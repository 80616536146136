@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500&display=swap');

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  margin: 7% 5%;
  background-color: #C8A37E;
}

section {
  margin: 7% 0;
}

p, a {
  font-size: 1.5vw;
}

img {
  width: 100%;
}

.navbar {
  width: 100%;
  display: flex;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
} 

.logo {
  font-size: 2.5vw;
  color: black;
  font-weight: 500;
}
.nav_item {
  margin-left: 10px;
  color: black;
  font-weight: 400;
}
.mobile_menu {
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
}
.bar {
  width: 25px;
  height: 1px;
  background-color: black;
  border:1px solid black;
  margin: 5px 0;
}
.hero_text {
  padding-bottom: 3%;
  font-size: 2.5vw;
  font-weight: 500;
}

.hero_link {
  color: black;
  font-weight: 500;
}

.portfolio_slideshow_container {
  width: 80%;
  padding-top: 80%; /* 1:1 Aspect Ratio */
  position: relative; 
}

.portfolio_slideshow_container_item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.portfolio_img {
  width: 80%;
} 

.portfolio_content {
  width: 80%;
  display: flex;
  align-items: flex-start;
  padding: 5% 0;
}

.portfolio_title {
  width: 50%;
  text-transform: uppercase;
  font-size: 2vw;
  font-weight: 500;
}

.portfolio_description {
  width: 50%;
  font-weight: 400;
  font-size: 1.5vw;
}

.portfolio_paragraph {
  width: 100%;
  text-align: justify;
  font-weight: 400;
  font-size: 1.5vw;
}

.portfolio_video {
  width: 100%;
}

#portfolio_video_blend {
  mix-blend-mode: multiply;
}

.portfolio_table{
  width: 100%;
  border-collapse: collapse;

}

.portfolio_table_row{
  font-weight: 400;
  font-size: 1.2vw;
  border: solid;
  border-width: 1px 0;
  height: 50px;
}

.portfolio_table_head{
  font-size: 2vw;
  font-weight: 500;
}

thead {
  line-height: 70px;
}

tr:last-child {
  border-bottom: none;
}

footer a {
  text-decoration: underline;
  color: black;
}

.cspace:hover{
  color: yellow;
  /* cursor:pointer; */
}

@media screen and (max-width: 767px) {
  .portfolio_img, .portfolio_content {
      width: 100%;
  } 
  .logo {
      font-size: 5vw;
  }
  .hero_text {
      font-size: 4vw;
  }
  p, a {
      font-size: 2.5vw;
  }
  .mobile_menu {
      display: flex;
  }
  .menu {
      display: none;
  }
  .portfolio_title {
      font-size: 4vw;
  }
  .portfolio_description{
      font-size: 2vw;
  }
}
